import React from "react";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";
import { Link as ScrollLink } from "react-scroll";
import Loading from "../components/Loading";
import StartUpImage from "../components/StartUpImage";
import { motion } from "framer-motion";
import { saveAs } from "file-saver";
//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";
import "../css/imageDownload.css";

//Stylesheets new
import "../css/Home.css";

const Medien = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { loading, error, data } = useFetch(wpURL + "medien?per_page=100");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  const media = data[0];

  let imgArr = [];

  function getIMG(string) {
    let img = "hello";
    if (string.indexOf("<img") !== -1) {
      string = string.substring(string.indexOf("<img"));
      img = string.substring(string.indexOf('src="') + 5, string.indexOf(">"));
      img = img.substring(0, img.indexOf('"'));
      imgArr.push(img);
      //console.log(imgArr);
      string = string.substring(string.indexOf(">") + 1);
      getIMG(string);
    }
  }
  function replaceIMG(string) {
    if (string.indexOf("<img") === -1) {
      return string;
    }
    return string.substring(0, string.indexOf("<img"));
  }

  getIMG(media.content.rendered);

  const saveFile = (media) => {
    media = media.replace("http:", "https:");
    saveAs(media, media);
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <title>Medien</title>
      <StartUpImage image={media.acf.titleimage} />
      <motion.div
        className="startBox"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
      >
        <h1
          className={`${"startText"} ${"font2"}`}
          style={{ fontWeight: "600" }}
        >
          <ReactMarkdown>{media.title.rendered}</ReactMarkdown>
        </h1>
        <h2 className={`${"aboutStart"} ${"aboutText"}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: replaceIMG(media.content.rendered),
            }}
          ></div>
        </h2>
        <ScrollLink to="target" smooth={true} className={`${"startButton"}`}>
          MEHR ERFAHREN
        </ScrollLink>
      </motion.div>
      <div id="target"></div>
      <div className="blogHeading" style={{ marginBottom: "100px" }}>
        <h2 style={{ margin: "auto" }}>{media.acf.title}</h2>
        <p style={{ margin: "auto", textAlign: "center" }}>
          {media.acf.markdownpagedescription}
        </p>
      </div>
      {imgArr.map((media) => (
        <section className="imageContainer">
          <img className="downloadImage" src={media}></img>
          <a
            className="downloadButton buttonTXT2 font2"
            onClick={() => saveFile(media)}
            style={{ cursor: "pointer" }}
          >
            Download
          </a>
        </section>
      ))}
    </div>
  );
};

export default Medien;

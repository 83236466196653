import React from "react";
import Loading from "../components/Loading";
import { wpURL } from "../components/URL";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";
import "../css/Blog.css";

const FanClubLink = () => {
  const { loading, error, data } = useFetch(wpURL + "fanclub?per_page=100");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;
  const fanclub = data[0];
  console.log(fanclub.acf.titleimage);
  return (
    <div
      className="w-full rounded-2xl flex sm:flex-row flex-col"
      style={{ boxShadow: "0px 0px 15px 3px rgba(0,0,0,0.1)" }}
    >
      <div
        className="sm:w-[70%] w-full sm:h-full h-[200px] sm:rounded-l-2xl sm:rounded-r-none rounded-t-2xl"
        style={{
          backgroundImage: `url(${fanclub.acf.titleimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="p-8 sm:px-20 flex flex-col">
        <ReactMarkdown>{fanclub.acf.markdown_werbeslogan}</ReactMarkdown>
        <a
          className="w-full rounded-lg p-3 mt-8 text-center shadow-xl bg-[rgba(55,55,55,1)] text-white font-bold transform transition-transform hover:-translate-y-0.5"
          href="/fanclub"
          target="_self"
        >
          {fanclub.acf.zumfanclubtext}
        </a>
      </div>
    </div>
  );
};

export default FanClubLink;

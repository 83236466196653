import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { wpURL } from "../components/URL";
import "../css/NextBlog.css";

const NextBlog = (blogID) => {
  const { loading, error, data } = useFetch(wpURL + "blogs?per_page=100");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const getAdjacentBlogs = (blogs, currentBlogId) => {
    // Find the index of the current blog
    console.log(blogs[0].id);
    console.log(currentBlogId);
    const currentIndex = blogs.findIndex((blog) => blog.id === currentBlogId);
    console.log("currentIndex", currentIndex);

    // Ensure currentIndex is valid
    if (currentIndex === -1) {
      return { previous: null, next: null };
    }

    // Get the previous and next blogs
    const previous = blogs[currentIndex - 1] || null;
    const next = blogs[currentIndex + 1] || null;

    return { previous, next };
  };

  const datablogs = data;

  datablogs.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  datablogs.reverse();

  const np_blogs = getAdjacentBlogs(datablogs, parseInt(blogID.blogID));

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-16">
      {np_blogs.previous && (
        <a
          href={`/blog/${np_blogs.previous.id}`}
          className="flex w-full gap-1 hover:opacity-75 text-black"
          target="_self"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left flex-none"
          >
            <>
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </>
          </svg>
          <div>
            <span class="font-bold">Vorheriger Post</span>
            <div class="text-sm text-skin-accent/85">
              {np_blogs.previous.title.rendered}
            </div>
          </div>
        </a>
      )}
      {np_blogs.next && (
        <a
          href={`/blog/${np_blogs.next.id}`}
          className="flex w-full justify-end gap-1 text-right hover:opacity-75 sm:col-start-2 text-black"
          target="_self"
        >
          <div>
            <span class="font-bold">Nächster Post</span>
            <div class="text-sm text-skin-accent/85">
              {np_blogs.next.title.rendered}
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right flex-none"
          >
            <>
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 6l6 6l-6 6" />
            </>
          </svg>
        </a>
      )}
    </div>
  );
};

export default NextBlog;

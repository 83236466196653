import React from "react";
import useFetch from "../hooks/useFetch";
import { strapiURL, wpURL } from "../components/URL";
import ReactMarkdown from "react-markdown";

//Stylesheets

import "../css/InfoBox01.css";
import "../css/videoBoxes02Style.css";
import "../css/Boxes02Style.css";
import "../css/TextOpenSans.css";
import "../css/Footer01.css";
import "../css/StartIMG01-01.css";

//Stylesheets new
import "../css/Home.css";

const News = (content) => {
  const acf = content.content;

  return (
    <div
      className="imageBox"
      style={{ backgroundImage: `url(${acf.newsimage})` }}
    >
      <div className="leftBox">
        <div className="textBox">
          <h2 className="text-xl font-bold">{acf.newstitle}</h2>
          <ReactMarkdown>{acf.newscontent}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default News;
